<template>
    <div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;">
            <p class="title_p">{{ title }}：</p>
            <el-select v-model="select_value" @change="get_val" :placeholder="`请选择${title}`">
                <el-option v-for="(item, index) in class_name === 'design_unit' ? unit_list : list" :label="item"
                    :key="index" :value="index">
                </el-option>
            </el-select>
        </div>
        <div class="flex" style="align-items: center;margin-bottom: 20px;" v-if="class_name === 'design_unit'">
            <p class=" title_p">设计规格：</p>
            <el-input :disabled="select_value === ''" @blur="change_specification" v-model="specification"
                :placeholder="select_value == '尺寸' ? '200px*300px' : select_value =='时长' ? '例：15s' : '请选择设计单位'"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        class_name: String,
        list: {
            type: [Array, Object],
            default: () => []
        },
        add_value: {
            type: [Number, String],
            default: ''
        },
        design_rule: {
            type: String,
            default: ''
        }
    },
    watch: {
        add_value(newVal, oldVal) {
            if (this.add_value && newVal && oldVal !== newVal) {
                // this.select_value = this.add_value
                for (const key in this.list) {
                    if (this.add_value == key) {
                        this.select_value = this.list[key]
                    }
                }
            }
            if (this.class_name === 'design_unit' && newVal && oldVal !== newVal) {
                this.select_value = this.unit_list[this.add_value]
            }
        },
        design_rule(val) {
            if (val) {
                this.specification = val
            }
        },
    },
    data() {
        return {
            specification: '',
            select_value: '',
            unit_list: {
                1: '尺寸',
                2: '时长'
            },
        }
    },
    methods: {
        get_val(val) {
            // 判断是否是单位联动选择器:unit
            console.log(this.select_value);
            this.$emit('get_val', this.class_name, val)
        },
        change_specification() {
            this.$emit('design_rule_change', this.specification)
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/AddResource';
</style>