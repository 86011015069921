<template>
    <div>
        <p class="add_title" v-show="!$route.query.id" @click="boxshow">
            还没有{{ class_name }}?,立即添加
        </p>
        <el-dialog title="添加平台" :append-to-body="true" :visible.sync="addbox" :show-close="false">
            <el-row :gutter="20">
                <el-col :span="2.8">
                    <div class="grid-content bg-purple">
                        <p class="gateway_title">平台名称:</p>
                    </div>
                </el-col>
                <el-col :span="18.2">
                    <div class="grid-content bg-purple">
                        <el-input v-model="gatewayForm.gatewayTitle" placeholder="请输入名称"></el-input>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="2.8">
                    <div class="grid-content bg-purple">
                        <p class="gateway_title">平台链接:</p>
                    </div>
                </el-col>
                <el-col :span="18.2">
                    <div class="grid-content bg-purple">
                        <el-input v-model="gatewayForm.gatewayUrl" placeholder="请输入链接"></el-input>
                    </div>
                </el-col>
                <div class="sub_box flex">
                    <button class="sub" @click="addPlat">提交</button>
                </div>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        category_id: String,
        class_name: {
            type: String,
            default: '门户',
        },
    },
    data() {
        return {
            addbox: false,

            gatewayForm: {
                gatewayTitle: '',
                gatewayUrl: '',
            },
        }
    },
    methods: {
        boxshow() {
            this.addbox = !this.addbox;
        },
        // 添加平台
        addPlat() {
            this.curlPost('api/users/medium/apply_portal', {
                category_id: this.category_id,
                title: this.gatewayForm.gatewayTitle,
                url: this.gatewayForm.gatewayUrl.indexOf('http') === -1 ? this.gatewayForm.gatewayUrl : `https://${this.gatewayForm.gatewayUrl}`,
            }).then((res) => {
                if (res.data.code) {
                    this.addbox = false
                    return this.$message({
                        type: 'success',
                        message: '已上传,待审核',
                    })
                } else {
                    return this.$message({
                        type: 'warning',
                        message: res.data.msg,
                    })
                }
            })
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/AddResource';
</style>