<template>
    <div class="flex" style="padding: 10px 0;align-items: center;margin-bottom: 20px;">
        <p class="title_p">{{ title }}：</p>
        <div class="flex" style="align-items: center;" v-for="(item, index) in delivery" :key="index">
            <el-input @blur="changeValue" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
                oninput="if(!/^[0-9]+$/.test(value)) value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); if((value[0] == 0 && value[1] > 0) || value == '00')value=value.slice(1);"
                v-model="item.value" :placeholder="`输入${item.label}价格`"></el-input>
            <div style="color: rgba(111, 144, 166, 1); font-size: 14px;margin:0 10px;">/{{ item.label }}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        form_data: {
            type: [Array, Object, String],
            default: () => []
        },
    },
    watch: {
        form_data(newVal, oldVal) {
            if (newVal && this.watch_number === 0 && this.$route.query.id) {
                this.watch_number += 1
                console.log(this.watch_number);
                this.delivery.forEach((item, index) => {
                    item.value = this.form_data[index]
                })
                this.changeValue()
            }
        }
    },
    data() {
        return {
            watch_number: 0,
            delivery: [
                {
                    value: '',
                    label: '天'
                },
                {
                    value: '',
                    label: '周'
                },
                {
                    value: '',
                    label: '月'
                },
                {
                    value: '',
                    label: '次'
                },
                {
                    value: '',
                    label: '期'
                },
            ],
        }
    },
    methods: {
        changeValue() {
            let value = this.delivery.map(item => item.value)
            this.$emit('change_value', value)
        }
    },
}
</script>
<style lang=scss scoped>
@import '@/scss/AddResource';

::v-deep .el-input {
    width: 95px !important;
    height: 40px !important;
}
</style>
