<template>
    <div>
        <!-- 批量上传 -->
        <p @click="mediumUpload" class="multiple" style="margin-left: 82px;">
            您有多个广告?请点击此处
        </p>
        <el-dialog title="批量上传媒体" width="672px" :append-to-body="true" :visible.sync="mediumBox" :show-close="false">
            <div style="justify-content: center;align-items: center;text-align: center;" class="flex">
                <el-upload class="upload-demo" accept=".xlsx" :headers="headerObj" drag action="/api/common/upload"
                    :on-success="Successxlsx" :before-remove="handleRemove">
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">点击上传</div>
                </el-upload>
            </div>

            <div class="flex" style="justify-content: center;margin-top: 20px;">
                <a class="multiple_a" target="_blank" href="/guanggao_info.xlsx">下载模板</a>

                <button class="upLoad_but" @click.stop="upLoadXlsx">确认上传</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            mediumBox: false,
            headerObj: {
                token: this.$user_info.token,
            },
            form: {
                mediaUrl: ''
            }
        }
    },
    methods: {
        mediumUpload() {
            this.mediumBox = true
        },
        // 批量上传媒体
        Successxlsx(res, file) {
            console.log(res, file)
            this.form.mediaUrl = res.data.url
        },
        upLoadXlsx() {
            this.curlGet('/api/users/guanggao_info/import', {
                file: this.form.mediaUrl
            }).then(res => {
                console.log(res);
                if (res.data.code) {
                    this.$message({
                        message: '上传成功,待审核',
                        type: 'success'
                    });
                    this.form.mediaUrl = ''
                    return this.mediumBox = false
                } else {
                    this.$message.error('上传失败');
                }
            })
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.form.mediaUrl = ''
        },
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/AddResource';
</style>