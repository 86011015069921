let user_info = JSON.parse(localStorage.getItem('user_info'))
const config = {
    // 编辑器不自动被内容撑高
    autoHeightEnabled: false,
    // 初始容器高度
    initialFrameHeight: 400,
    // 初始容器宽度
    initialFrameWidth: '100%',
    // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
    serverUrl: `/api/common/getupload?token=${user_info.token}`,
    // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
    UEDITOR_HOME_URL: '/UEditor/',
    initialContent: '1.所提供文章必须符合国家广告法内容，若文章存在违规被删除，本平台不予处理；<br/>2.Word文档上传后请仔细检查内容是否有误，一经发布无法撤回和修改，请确认后再操作；<br/>3.署名、作者名、免责声明等特殊内容不保证，网媒没标明不包时效的，默认1个月。<br/>4.图片文件最大上传不超过3MB；<br/>5.视频文件最大上传不超过20MB；',
    autoClearinitialContent: true,
    toolbars: [
        [
            "fullscreen",
            "source",	// html 源码
            "|",
            "undo",
            "redo",
            "|",
            "link",
            "unlink",
            "|",
            "simpleupload",
            "insertimage",
            "insertvideo",
            "|",
            "bold",
            "forecolor",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "fontfamily",
            "fontsize",
            "|",
            "removeformat",
            "formatmatch",
            "autotypeset",
        ]
    ],
}
export default config